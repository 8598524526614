import { openModal, closeModal } from '../../shared/modal';
import { delay } from '../../shared/delay';

function createListDialog() {
    // Create dialog element
    const dialog = document.createElement('dialog');
    dialog.id = 'list-modal';

    // Create and append article to dialog
    const article = document.createElement('article');

    // Create and append h3 to article
    const h3 = document.createElement('h3');
    h3.id = 'list-title';
    article.appendChild(h3);

    // Create and append h5 to article
    const h5 = document.createElement('h5');
    h5.id = 'list-desc';
    article.appendChild(h5);

    // Create and append input for search bar if required
    const searchBar = document.createElement('input');
    searchBar.type = 'text';
    searchBar.id = 'search-bar';
    searchBar.placeholder = 'Search...';
    searchBar.style.display = 'none'; // Hide by default
    article.appendChild(searchBar);

    // Create and append table for list items
    const table = document.createElement('table');
    table.id = 'list-table';
    article.appendChild(table);

    // Create and append footer to article
    const footer = document.createElement('footer');
    const a = document.createElement('a');
    a.id = 'list-modal-button';
    a.href = '#';
    a.setAttribute('role', 'button');
    a.dataset.target = 'list-modal';
    a.textContent = 'Close';
    footer.appendChild(a);

    // Append footer to article, then article to dialog
    article.appendChild(footer);
    dialog.appendChild(article);

    // Append dialog to body
    document.body.appendChild(dialog);
}

export async function showListDialog(title, description, items, includeSearchBar = false) {
    return new Promise((resolve, reject) => {
        // Ensure the dialog exists, create if not
        if (!document.getElementById('list-modal')) createListDialog();

        // Set title and description
        document.getElementById('list-title').innerHTML = title;
        document.getElementById('list-desc').innerHTML = description;

        // Set search bar visibility
        const searchBar = document.getElementById('search-bar');
        searchBar.style.display = includeSearchBar ? 'block' : 'none';

        // Populate table with items
        const table = document.getElementById('list-table');
        table.innerHTML = ''; // Clear any existing rows
        items.forEach((item, index) => {
            const row = table.insertRow();
            const cell = row.insertCell(0);
            cell.textContent = item;
            cell.dataset.index = index;
            row.onclick = () => {
                closeModal(document.getElementById('list-modal'));
                resolve(item);
            };
        });

        // Add search functionality
        if (includeSearchBar) {
            searchBar.oninput = () => {
                const filter = searchBar.value.toLowerCase();
                Array.from(table.rows).forEach(row => {
                    const cell = row.cells[0];
                    if (cell.textContent.toLowerCase().includes(filter)) {
                        row.style.display = '';
                    } else {
                        row.style.display = 'none';
                    }
                });
            };
        }

        // Handle modal close without selection
        const closeButton = document.getElementById('list-modal-button');
        closeButton.onclick = async function () {
            closeModal(document.getElementById('list-modal'));
            await delay(500); // wait for the modal to close
            reject(new Error('Modal closed without selection'));
            // Remove the dialog from the DOM
            document.getElementById('list-modal').remove();
        };

        // Open the modal
        openModal(document.getElementById('list-modal'));
    });
}
