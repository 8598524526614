/* global gapi, google */
// ^^ tells eslint these exist, gapi is defined in the HTML script tag ^^ //

// Google Drive utilities
export function createPicker(token) {
    return new Promise((resolve, reject) => {
        gapi.load('picker', {
            callback: function() {
                const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
                    .setIncludeFolders(true)
                    .setSelectFolderEnabled(true);

                const picker = new google.picker.PickerBuilder()
                    .setOAuthToken(token)
                    .setDeveloperKey('AIzaSyCVUkgW80UKt2bFXnx8HbTwgbNeEGKJH-k')  // Replace with your API key
                    .addView(view)
                    .setCallback((data) => {
                        const action = data[google.picker.Response.ACTION];
                        
                        if (action === google.picker.Action.PICKED) {
                            const folderId = data[google.picker.Response.DOCUMENTS][0].id;
                            resolve(folderId);
                        } else if (action === google.picker.Action.CANCEL) {
                            reject('Picker cancelled by the user.');
                        }
                        // No need to reject for other actions, just handle PICKED and CANCEL
                    })
                    .build();
                picker.setVisible(true);
            }
        });
    });
}

export async function uploadFileToDrive(fileData, fileName, folderId, token) {
    const metadata = {
        name: fileName,
        parents: [folderId]
    };

    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', fileData);

    const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: new Headers({ 'Authorization': 'Bearer ' + token }),
        body: form
    });

    return response.json();
}