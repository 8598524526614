export function playSuccessSound() {
    // play the success sound
    const audioURL = new URL('../../../sound/success.mp3', import.meta.url);
    var audio = new Audio(audioURL);
    audio.volume = 1;
    audio.play();
}

export function playErrorSound() {
    // play the error sound
    const audioURL = new URL('../../../sound/error.mp3', import.meta.url);
    var audio = new Audio(audioURL);
    audio.volume = 1;
    audio.play();
}

export function playPictureSound() {
    // play the picture sound
    const audioURL = new URL('../../../sound/picture.mp3', import.meta.url);
    var audio = new Audio(audioURL);
    audio.volume = 1;
    audio.play();
}