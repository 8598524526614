export function capitaliseName(name) {
    // List of particles that should remain lowercase unless they're the first word
    const lowercaseParticles = ['de', 'von', 'van', 'da', 'di', 'del', 'della', 'du'];

    return name
        .trim() // Remove any leading or trailing whitespace
        .split(/([-\s'])/) // Split by spaces, hyphens, or apostrophes, and keep the separators
        .map((word, index) => {
            const lowercasedWord = word.toLowerCase();

            // If the word is a separator (space, hyphen, or apostrophe), return it as is
            // eslint-disable-next-line quotes
            if (word === '-' || word === ' ' || word === "'") {
                return word;
            }

            // Capitalise the word unless it's a lowercase particle and not the first word
            if (index !== 0 && lowercaseParticles.includes(lowercasedWord)) {
                return lowercasedWord;
            }

            // Capitalise the first letter and lowercase the rest
            return lowercasedWord.charAt(0).toUpperCase() + lowercasedWord.slice(1);
        })
        .join('') // Join back the name parts with their original separators
        .replace(/\s+/g, ' '); // Ensure single spaces between words
}