import { playPictureSound, playSuccessSound } from '../kiosks/utils/sound.js';
import { showError } from '../shared/error';
import { db, storage, functions } from '../shared/firebase.js';
import { collection, Timestamp, addDoc, updateDoc, getDocs, query, where, deleteDoc, doc, getDoc, orderBy } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { showListDialog } from './utils/list.js';
import { delay } from '../shared/delay.js';
import { getGoogleToken } from './utils/auth.js';
import { createPicker, uploadFileToDrive } from './utils/drive.js';
import { capitaliseName } from './utils/names.js';
import { humanReadableDate } from './utils/date-format.js';
import { createProgressBar } from './utils/progress.js';

window.currentEnrolmentTab = 'staff';
var takingAgain = false;
var studentWindow = null;
var photoTaken = false; // has a photo been taken and can it be saved?
var nextSavedPhoto = null; // the next photo to be saved
var targetCamera = null; // camera to use
var currentViewingPhoto = null; // the photo currently being viewed
var lastChecked = null; // the last checked checkbox

export async function prepareEnrolmentMode() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        // Request camera permission
        try {
            await navigator.mediaDevices.getUserMedia({ video: true });
        } catch (error) {
            console.error('Camera permission denied:', error);
            document.getElementById('enrolment-mode-button').setAttribute('aria-busy', 'false');
            showError('4002', 'Camera Permission Denied', 'Please grant Inscribe access to your camera to use enrolment mode.', error, false, true, false);
            reject(new Error('Camera Permission Denied'));
            return; // Stop execution if camera permission is denied
        }

        // Check we can contact the extension
        try {
            await chrome.runtime.sendMessage('cflgknflngllneobhihdkdfplcgkpoil', { ping: true }, function () {
                if (chrome.runtime.lastError) {
                    showError('4002', 'Extension Error', 'Unable to contact the extension. Please ensure it is installed, and try again.', chrome.runtime.lastError, false, false, false);
                    document.getElementById('enrolment-mode-button').setAttribute('aria-busy', 'false');
                    reject(new Error('Extension Error'));
                    return; // Stop execution if we can't contact the extension
                } else {
                    console.log('Sent enrolment mode tab focus message');
                    resolve();
                }
            });
        } catch (error) {
            console.warn('Error contacting extension to focus enrolment mode tab:', error);
            showError('4002', 'Extension Error', 'Unable to contact the extension. Please ensure it is installed, and try again.', null, false, false, false);
            document.getElementById('enrolment-mode-button').setAttribute('aria-busy', 'false');
            reject(new Error('Extension Error'));
            return; // Stop execution if we can't contact the extension
        }
    });
}
export async function loadEnrolmentMode() {
    console.log('loading enrolment mode');

    // Open the student enrolment window
    const popupWidth = 800;
    const popupHeight = 600;
    const left = (screen.width - popupWidth) / 2;
    const top = (screen.height - popupHeight) / 2;

    // if development or staging, add a dev param
    let devParam = '';
    // we have to use the staging domain for camera to work (https)
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === 'inscribe-mgka-staging.web.app') {
        devParam = '?dev=true';
    }

    studentWindow = window.open(
        `enrolment-student${devParam}`,
        '',
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );

    // Ensure the student window is loaded before sending the init message
    studentWindow.onload = () => {
        console.log('Student window loaded, sending init message');
        studentWindow.postMessage({ type: 'init' }, window.location.origin);
    };


    // Set up the event listeners for the staff window UI
    setUpStaffUI();

    // Close the student window when the staff window is closed or reloaded
    window.addEventListener('beforeunload', () => {
        // keep open in dev mode
        if (studentWindow && devParam === '') {
            studentWindow.close();
        }
    });

    // Listen for messages from the student window
    window.addEventListener('message', (event) => {
        if (event.origin === window.location.origin) {
            const message = event.data;
            handleIncomingMessage(message);
        } else {
            console.warn('Received message from unknown origin:', event.origin);
        }
    });
}

async function savePhoto() {
    console.log('Saving photo');

    // check the student name
    if (!document.getElementById('staff-name').value || document.getElementById('staff-name').value.length === 0) {
        console.error('Student name is empty');
        showError('3020', 'Error Saving Photo', 'Please enter a student name.', null, true, false);
        return;
    }

    // check the photo
    if (!photoTaken || nextSavedPhoto === null) {
        console.error('Photo is empty');
        showError('3021', 'No Photo Taken', 'Please take a new photo for this student.', null, true, false);
        return;
    }

    try {
        // create a new photo record in Firestore
        console.log(`creating record in domains/${window.userDomain}/photos`);
        const photoRef = await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos'), {
            studentName: document.getElementById('staff-name').value,
            timestamp: Timestamp.now(),
            adminID: window.userID,
        });

        console.log('Photo saved to Firestore with ID:', photoRef.id);

        // Convert the data URL to a Blob
        const blob = await (await fetch(nextSavedPhoto)).blob();

        const imagePath = `domains/${window.userDomain}/photos/${photoRef.id}`;
        console.log('Uploading photo to', imagePath);

        // Get a reference to the storage location
        const storageRef = ref(storage, `domains/${window.userDomain}/photos/${photoRef.id}`);

        console.log('Uploading photo to', storageRef.fullPath);

        // Upload the photo to Firebase Storage
        try {
            await uploadBytes(storageRef, blob);
            console.log('Upload complete');
        } catch (error) {
            console.error('Error uploading photo:', error);
            showError('3022', 'Error Uploading Photo', 'An error occurred while uploading the photo.', null, true, false);
            showStudentToast('error', 'Error Uploading Photo');
            return;
        }


        // Add the storage ref to the Firestore photo record
        await updateDoc(photoRef, {
            storageRef: storageRef.fullPath
        });

        // add the last-photo-name
        document.getElementById('last-photo-name').innerHTML = `Last Photo: ${document.getElementById('staff-name').value}`;
        // clear the name input
        document.getElementById('staff-name').value = '';

        playSuccessSound();

        // set the next student button to green for 2 seconds
        document.getElementById('next-student').style.backgroundColor = 'green';
        setTimeout(() => {
            document.getElementById('next-student').style.backgroundColor = 'var(--secondary)';
        }, 2000);

        // hand control back to the student
        sendMessageToStudentWindow({ type: 'clear' });
        showStudentToast('success', 'Photo Saved');
        await toggleStudentMode();
        photoTaken = false;
        nextSavedPhoto = null;
    } catch (error) {
        console.error('Error saving photo to Firestore:', error);
        showError('3023', 'Error Saving Photo', 'An error occurred while saving the photo.', null, true, false);
        showStudentToast('error', 'Error Saving Photo');
    }
}


function handleIncomingMessage(message) {
    if (message.type === 'name') {
        document.getElementById('staff-name').value = message.value;
    } else if (message.type === 'photo') {
        handleIncomingPhoto(message);
    } else if (message.type === 'confirm-init') {
        console.log('Initialization confirmed by student window');
    } else if (message.type === 'close') {
        console.log('Student window closed');
        // reload the page - can't close the window.
        window.location.reload();
    }
}

function showStudentToast(type, message) {
    sendMessageToStudentWindow({ type: 'showToast', value: { type, message } });
}

function setUpStaffUI() {
    document.getElementById('staff-name').addEventListener('input', (event) => {
        const name = event.target.value;
        sendMessageToStudentWindow({ type: 'name', value: name });
    });

    document.getElementById('staff-name').addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            // fix the name format
            document.getElementById('staff-name').value = capitaliseName(document.getElementById('staff-name').value);
            // update the student window with the formatted name
            sendMessageToStudentWindow({ type: 'name', value: document.getElementById('staff-name').value });
        }
    });

    // if the box is unfocused, fix the name format
    document.getElementById('staff-name').addEventListener('blur', function () {
        // fix the name format
        document.getElementById('staff-name').value = capitaliseName(document.getElementById('staff-name').value);
        // update the student window with the formatted name
        sendMessageToStudentWindow({ type: 'name', value: document.getElementById('staff-name').value });
    });

    document.getElementById('take-photo').addEventListener('click', () => {
        console.log('Take photo button clicked');
        if (takingAgain) {
            // taking a photo of this same student again
            console.log('Taking again');
            // start our camera and the student camera again
            startCamera(true);
            sendMessageToStudentWindow({ type: 'start' });
            takingAgain = false;
            document.getElementById('take-photo').innerText = 'Take Photo';
        }

        else {
            // taking a photo of a new student (or first time)
            console.log('Taking photo');
            sendMessageToStudentWindow({ type: 'photo' });
            playPictureSound();
            showStudentToast('success', 'Photo Taken');
        }
    });

    document.getElementById('next-student').addEventListener('click', async () => {
        console.log('Next student button clicked');
        document.getElementById('next-student').setAttribute('aria-busy', 'true');
        await savePhoto();
        // reset to Take Photo
        document.getElementById('take-photo').innerText = 'Take Photo';
        takingAgain = false;
        document.getElementById('next-student').setAttribute('aria-busy', 'false');
    });

    // ctrl + S to switch between student and staff
    document.addEventListener('keydown', function (event) {
        if (event.ctrlKey && event.key === 's') {
            // ignore browser shortcuts
            event.preventDefault();
            toggleStudentMode();
        }
    });

    startCamera();
}

function sendMessageToStudentWindow(message) {
    if (studentWindow) {
        studentWindow.postMessage(message, window.location.origin);
    } else {
        console.warn('Student window is not defined');
    }
}

// when we get sent a photo that's been taken
async function handleIncomingPhoto(data) {
    console.log('Displaying photo in staff window');
    document.getElementById('staff-photo').src = data.value;
    document.getElementById('staff-photo').style.display = 'block';

    // remove the last student's name from the last taken photo
    document.getElementById('last-photo-name').innerText = 'Latest Photo';

    // update the button to say 'Retake Photo'
    document.getElementById('take-photo').innerText = 'Retake Photo';
    takingAgain = true;
    photoTaken = true;
    nextSavedPhoto = data.value;
}

// Show the camera feed live to the staff
async function startCamera(retake = false) {
    if (!targetCamera) {
        console.warn('No camera selected, selecting one');
        // need to select a cam
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const cameras = mediaDevices.filter(device => device.kind === 'videoinput');
        console.log('Cameras:', cameras);

        // if there are no cameras, show an error
        if (cameras.length === 0) {
            showError('3024', 'No Cameras Found', 'Please connect a camera to your device.', null, true, false);
            return;
        }

        // if there is only one camera, use it
        if (cameras.length === 1) {
            targetCamera = cameras[0];
        }

        // if there are multiple cameras, ask the user which one to use
        else {
            const cameraList = cameras.map(camera => ({
                originalLabel: camera.label,
                displayLabel: camera.label.replace(/\s*\(.*?\)$/, '')
            }));

            // with the ID removed for users to click
            const displayLabels = cameraList.map(camera => camera.displayLabel);

            await showListDialog('Select Camera', 'Please select the camera to use.', displayLabels, false).then(async (selectedCamera) => {
                const selectedCameraLabel = cameraList.find(camera => camera.displayLabel === selectedCamera).originalLabel;
                console.log('Selected camera:', selectedCameraLabel);

                targetCamera = cameras.find(camera => camera.label === selectedCameraLabel);
                console.log('Selected camera object:', targetCamera);
            }).catch(async error => {
                console.error('Error selecting camera:', error);
                // reload the page - this also closes the student window
                await delay(500);
                window.location.reload();
            });

        }

        sendMessageToStudentWindow({ type: 'cameraStart', value: targetCamera.deviceId });
    }

    console.log('Starting camera with device ID:', targetCamera.deviceId);

    const constraints = {
        video: {
            deviceId: targetCamera.deviceId ? { exact: targetCamera.deviceId } : undefined
        },
        audio: false
    };
    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints, targetCamera);
        console.log('Camera stream started');
        document.getElementById('camera-view').srcObject = stream;

        // don't focus student window if we're retaking - no need to enter name etc, just a viewfinder
        if (!retake) {
            // all ready, focus the student window
            updateTabFocus('student');
        }
    } catch (error) {
        console.error('Error accessing camera:', error);
    }
}

export async function updateTabFocus(targetTab) {
    // send a message to the chrome extension to focus the student tab
    await chrome.runtime.sendMessage('cflgknflngllneobhihdkdfplcgkpoil', { enrolmentModeTabFocus: true, targetTab: targetTab }, function () {
        if (chrome.runtime.lastError) {
            console.error('Error sending enrolment mode tab focus message:', chrome.runtime.lastError);
            throw new Error('Error sending enrolment mode tab focus message');
        } else {
            console.log('Sent enrolment mode tab focus message');
        }
    });
}

export async function toggleStudentMode() {
    // send a message to the chrome extension to toggle the tabs - returns the currentTab
    await chrome.runtime.sendMessage('cflgknflngllneobhihdkdfplcgkpoil', { enrolmentModeTabToggle: true }, function (response) {
        if (chrome.runtime.lastError) {
            console.error('Error sending enrolment mode tab toggle message:', chrome.runtime.lastError);
            throw new Error('Error sending enrolment mode tab toggle message');
        } else {
            console.log('Sent enrolment mode tab toggle message');
            const currentTab = response.currentTab;
            console.log('Current tab:', currentTab);
            window.currentEnrolmentTab = currentTab;
        }
    });
}

export async function loadViewEnrolmentMode() {
    console.log('Loading view enrolment mode...');
    // store checked checkboxes
    const checkedIds = Array.from(document.querySelectorAll('.student-checkbox:checked')).map(checkbox => checkbox.getAttribute('data-id'));
    // clear the table
    document.querySelector('#enrolment-table tbody').innerHTML = '';

    // put all the students from the db into the table
    const studentsQuery = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos'), where('studentName', '!=', null), orderBy('studentName'));
    const studentsQuerySnapshot = await getDocs(studentsQuery);

    // if no students found, show no students found
    if (studentsQuerySnapshot.size === 0) {
        console.log('No photos found');

        // add a no photos found row
        const row = document.createElement('tr');
        row.innerHTML = `
        <td colspan="2" style="text-align: center;" id="enrolment-no-photos-found">No photos found</td>
        `;
        document.querySelector('#enrolment-table tbody').appendChild(row);

        // hide the download button and the current student container
        document.getElementById('download-all-photos-button').style.display = 'none';
        document.getElementById('current-student-container').style.display = 'none';

        // hide the download & delete buttons
        document.getElementById('download-all-photos-button').style.display = 'none';
        document.getElementById('delete-selected-photos-button').style.display = 'none';

        // disable the checkbox and search box
        document.getElementById('select-all').disabled = true;
        document.getElementById('enrolment-search').disabled = true;
        return;
    }

    // show download button and the text
    document.getElementById('download-all-photos-button').style.display = 'block';
    if (!currentViewingPhoto) {
        document.getElementById('current-student-desc').style.display = 'block';
    }

    // enable the checkbox
    document.getElementById('select-all').disabled = false;
    // enable the search box
    document.getElementById('enrolment-search').disabled = false;

    console.log(`Found ${studentsQuerySnapshot.size} students`);

    // loop through students
    studentsQuerySnapshot.forEach((studentDoc) => {
        // get the student data
        const studentData = studentDoc.data();
        const row = document.createElement('tr');
        row.id = `student-photo-${studentDoc.id}`;

        // sanitisation of names is down when they are created - don't do now as we could ignore manual changes from the viewing UI
        const studentName = studentData.studentName;
        row.innerHTML = `
            <td><input type="checkbox" class="student-checkbox" data-id="${studentDoc.id}" data-name="${studentName}"></td>
            <td>${studentName}</td>
        `;

        // if it's the current photo, highlight the row and set the name
        if (currentViewingPhoto === studentDoc.id) {
            row.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');

        }

        // if it was previously checked, check the checkbox
        if (checkedIds.includes(studentDoc.id)) {
            row.querySelector('.student-checkbox').checked = true;
        }

        // add the row
        document.querySelector('#enrolment-table tbody').appendChild(row);

        row.addEventListener('mousedown', async function (event) {
            // Don't load the photo if a checkbox was clicked - or if the same photo is already being viewed
            if (event.target.type === 'checkbox' || currentViewingPhoto === studentDoc.id) {
                return;
            }

            // hide/reset data until the photo is loaded
            document.getElementById('current-student-buttons').style.display = 'none';
            document.getElementById('current-student-name').innerText = '';
            document.getElementById('current-student-time').innerText = '';
            document.getElementById('current-student-photo').src = '';
            document.getElementById('current-student-photo').setAttribute('aria-busy', 'true');
            document.getElementById('current-student-rename').style.display = 'none';

            // un-highlight all rows
            document.querySelectorAll('#enrolment-table tbody tr').forEach(row => {
                row.style.backgroundColor = '';
            });
            // highlight the row
            row.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');

            // Get the image URL from cloud storage
            const photoSnap = await getDownloadURL(ref(storage, studentData.storageRef));

            // Create a temporary Image object to load the image first
            let tempImg = new Image();

            // Hide the actual image element until fully loaded
            const imgElement = document.getElementById('current-student-photo');
            imgElement.style.visibility = 'hidden';

            // Set up an event listener to swap the image once it's fully loaded
            tempImg.onload = () => {
                // Set the attributes and display the image
                imgElement.src = tempImg.src;
                imgElement.style.visibility = 'visible';
                imgElement.style.display = 'block';
                imgElement.setAttribute('data-student-name', studentName);
                imgElement.setAttribute('data-photo-id', studentDoc.id);
                imgElement.setAttribute('data-storage-ref', studentData.storageRef);
                document.getElementById('current-student-time').innerText = `Taken: ${humanReadableDate(studentData.timestamp.toDate(), 'extended')}`;
                document.getElementById('current-student-name').innerText = studentName;
                document.getElementById('current-student-buttons').style.display = 'grid';
                document.getElementById('current-student-rename').style.display = 'inline';

                // hide current-student-desc
                document.getElementById('current-student-desc').style.display = 'none';
                imgElement.setAttribute('aria-busy', 'false');

                // remove the tempImg pointer
                tempImg = null;
            };

            // Start loading the image by setting the source
            tempImg.src = photoSnap;
            currentViewingPhoto = studentDoc.id;
        });

        // When the checkbox is clicked, update the UI
        row.querySelector('.student-checkbox').addEventListener('change', handleCheckboxChange);
    });

    // may not exist if the input is showing currently
    if (document.getElementById('current-student-name')) {
        // When the name is clicked, change to a text input to rename the student
        document.getElementById('current-student-name').addEventListener('click', function () {
            // hide the rename text
            document.getElementById('current-student-rename').style.display = 'none';
            const nameElement = this;
            const currentName = nameElement.innerText;

            // Create an input element
            const inputElement = document.createElement('input');
            inputElement.type = 'text';
            inputElement.value = currentName;
            inputElement.className = 'name-input'; // Add a class for styling if needed

            // Replace the h3 with the input element
            nameElement.replaceWith(inputElement);
            inputElement.focus();

            // Function to save the changes
            async function saveName() {
                const newName = inputElement.value.trim();

                // Update the h3 element with the new name
                nameElement.innerText = newName !== '' ? newName : currentName;

                // Replace the input with the h3 element
                inputElement.replaceWith(nameElement);

                inputElement.setAttribute('aria-busy', 'true');
                await handleStudentNameChange(currentViewingPhoto, newName).then(() => {
                    inputElement.setAttribute('aria-busy', 'false');
                    // show the rename text
                    document.getElementById('current-student-rename').style.display = 'inline';
                });
            }

            // Handle Enter key
            function handleKeyDown(event) {
                if (event.key === 'Enter') {
                    // Remove the blur event listener to prevent it from firing after Enter key press
                    inputElement.removeEventListener('blur', handleBlur);
                    saveName();
                }
            }

            // Handle blur event
            function handleBlur() {
                saveName();
            }

            // Attach event listeners
            inputElement.addEventListener('blur', handleBlur);
            inputElement.addEventListener('keydown', handleKeyDown);
        });
    }


    // add event listener for the download button
    document.getElementById('current-student-photo-download').addEventListener('mousedown', async () => {
        console.log('download button clicked');
        // disable the delete selected button & delete all button
        document.getElementById('delete-selected-photos-button').setAttribute('disabled', 'disabled');
        document.getElementById('delete-selected-photos-button').setAttribute('disabled', 'disabled');
        // show a list to select download or google drive
        await showListDialog('Download Photos', 'Please select an option.', ['Download as PNG', 'Add to Google Drive'], false).then(async (result) => {
            if (result === 'Download as PNG') {
                document.getElementById('current-student-photo-download').setAttribute('aria-busy', 'true');
                // download from the img src
                const blob = await fetch(document.getElementById('current-student-photo').src).then(response => response.blob());
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${document.getElementById('current-student-photo').getAttribute('data-student-name')}.png`;
                a.click();
            } else if (result === 'Add to Google Drive') {
                document.getElementById('current-student-photo-download').setAttribute('aria-busy', 'true');
                // Store the photo in Google Drive
                const photoUrls = [{ studentName: document.getElementById('current-student-photo').getAttribute('data-student-name'), url: document.getElementById('current-student-photo').src }];
                await storePhotosInDrive(photoUrls);
            }
        }).catch(async error => {
            console.error('Error showing list dialog:', error);
        });

        document.getElementById('current-student-photo-download').setAttribute('aria-busy', 'false');
        // enable the delete selected button & delete all button
        document.getElementById('delete-selected-photos-button').removeAttribute('disabled');
        document.getElementById('delete-selected-photos-button').removeAttribute('disabled');
    });

    // add event listener to delete student photo
    document.getElementById('current-student-photo-delete').addEventListener('click', async () => {
        // show a list to confirm deletion
        await showListDialog('Confirm Delete', 'Are you sure you want to delete this student\'s photo? This is permanent.', ['Yes', 'No'], false).then(async (selected) => {
            if (selected === 'Yes') {
                document.getElementById('current-student-photo-delete').setAttribute('aria-busy', 'true');
                const photoID = document.getElementById('current-student-photo').getAttribute('data-photo-id');
                console.log(`Deleting student photo with ID ${photoID}`);

                // delete the photo
                const photoRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos', photoID);
                await deleteDoc(photoRef);
                console.log('Deleted document in Firestore');

                // delete the photo from storage
                const storageRef = document.getElementById('current-student-photo').getAttribute('data-storage-ref');
                console.log(`Deleting photo from storage with ref ${storageRef}`);
                const photoStorageRef = ref(storage, storageRef);
                await deleteObject(photoStorageRef);
                console.log('Deleted photo from storage');

                // hide the photo
                document.getElementById('current-student-photo').style.display = 'none';
                document.getElementById('current-student-desc').style.display = 'block';
                document.getElementById('current-student-photo-download').style.display = 'none';
                document.getElementById('current-student-photo-delete').style.display = 'none';
                // remove the row from the table
                const row = document.getElementById(`student-photo-${photoID}`);
                if (row) {
                    row.remove();
                }

                currentViewingPhoto = null;
                document.getElementById('current-student-photo-delete').setAttribute('aria-busy', 'false');
                loadViewEnrolmentMode();
            }
        }).catch(async error => {
            console.error('Error showing delete confirmation dialog:', error);
            document.getElementById('current-student-photo-delete').setAttribute('aria-busy', 'false');
            // if the error is "Modal closed without selection", it means the user clicked the cancel button
            if (error.message === 'Modal closed without selection') {
                return;
            }
            showError('3025', 'Error Deleting Photo', 'An error occurred while deleting the photo.', null, false, false);
        });
    });

    // download all button
    document.getElementById('download-all-photos-button').addEventListener('mousedown', async () => {
        const selectedCheckboxes = document.querySelectorAll('.student-checkbox:checked');
        const downloadButton = document.getElementById('download-all-photos-button');
        const photoIds = Array.from(selectedCheckboxes).map(checkbox => checkbox.getAttribute('data-id'));
        console.log(`selected photos: ${photoIds}`);

        // Show a list - download, or store in Google Drive
        await showListDialog('Download Photos', 'Please select an option.', ['Download as ZIP', 'Add to Google Drive'], false).then(async (result) => {
            if (result === 'Download as ZIP') {
                console.log('Downloading as ZIP');
                // Download the zip
                downloadButton.setAttribute('aria-busy', 'true');
                // disable the delete selected button
                document.getElementById('delete-selected-photos-button').setAttribute('disabled', 'disabled');

                try {
                    // Call the cloud function with selected photo IDs or an empty array to download all
                    const downloadAllPhotos = httpsCallable(functions, 'downloadAllPhotos');
                    const domain = window.userDomain;
                    const interval = window.currentInterval;

                    await downloadAllPhotos({ domainId: domain, photoIds: photoIds, intervalId: interval }).then(response => {
                        const url = response.data.url;
                        // Download the zip
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `photos_${domain}.zip`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }).catch(error => {
                        console.error('Error downloading photos:', error.message);
                        // if the error message is "No photos found", show a specific error message
                        if (error.message === 'No photos found.') {
                            showError('3027', 'No Photos Found', 'There are no photos to download.', null, false, false);
                        } else {
                            // throw to the catch block
                            throw error;
                        }
                    });

                } catch (error) {
                    console.error('Error downloading photos:', error.message);
                    showError('3026', 'Error Downloading Photos', 'An error occurred while downloading the photos.', error, false, true, false);
                } finally {
                    // enable the delete selected button
                    document.getElementById('delete-selected-photos-button').removeAttribute('disabled');
                    downloadButton.setAttribute('aria-busy', 'false');
                }

            } else if (result === 'Add to Google Drive') {
                console.log('Adding to Google Drive');
                downloadButton.setAttribute('aria-busy', 'true');
                document.getElementById('delete-selected-photos-button').setAttribute('disabled', 'disabled');
                let photoUrls = [];

                if (photoIds.length === 0) {
                    console.log('No photos selected, getting all from DB');
                    // Query DB for all student photos
                    const studentPhotosRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos'), where('studentName', '!=', null));
                    const studentPhotosSnapshot = await getDocs(studentPhotosRef);

                    try {
                        // Extract the photo URLs in parallel
                        photoUrls = await Promise.all(studentPhotosSnapshot.docs.map(async (doc) => {
                            const studentName = doc.data().studentName;
                            const storageRef = doc.data().storageRef;
                            const url = await getDownloadURL(ref(storage, storageRef));
                            return { studentName, url };
                        }));

                        console.log('photoUrls:', photoUrls);

                    } catch (error) {
                        console.error('Error during photo URL extraction:', error);
                        showError('3027', 'Error Downloading Photos', 'An error occurred while downloading the photos.', null, false, true, false);
                        return;
                    }

                    // Store the photos in Google Drive - without a progress bar
                    await storePhotosInDrive(photoUrls).then(() => {
                        console.log('Drive adding complete');
                        // enable the delete selected button
                        document.getElementById('delete-selected-photos-button').removeAttribute('disabled');
                        downloadButton.setAttribute('aria-busy', 'false');
                    });

                } else {
                    console.log('Photos selected:', photoIds);

                    // determinate progress bar - x2 the total photos represent download + upload to drive
                    const progressBar = createProgressBar(document.getElementById('enrolment-progress-container'), { max: photoIds.length * 2, current: 0 });

                    // There are photos selected, get only those
                    try {
                        // Create an array of promises to get the photo documents and URLs concurrently
                        const photoPromises = photoIds.map(async (photoId) => {
                            const photoRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos', photoId);
                            const photoDoc = await getDoc(photoRef);
                            if (!photoDoc.exists()) {
                                console.error('Photo does not exist:', photoId);
                                showError('3027', 'Error Downloading Photos', 'An error occurred while downloading the photos.', null, false, true, false);
                                throw new Error('Photo does not exist');
                            }

                            const studentName = photoDoc.data().studentName;
                            const storageRef = photoDoc.data().storageRef;
                            const url = await getDownloadURL(ref(storage, storageRef));

                            return { studentName, url };
                        });

                        // Wait for all promises to resolve
                        const photoUrls = await Promise.all(photoPromises);

                        // Update the progress bar as each promise resolves
                        photoUrls.forEach((_, index) => {
                            progressBar.setProgress(index + 1); // Update the progress bar
                        });

                    } catch (error) {
                        console.error('Error during photo download:', error);
                        showError('3027', 'Error Downloading Photos', 'An error occurred while downloading the photos.', null, false, true, false);
                        return;
                    }

                    console.log('photoUrls:', photoUrls);

                    // Store the photos in Google Drive - with the progress bar
                    await storePhotosInDrive(photoUrls, progressBar);
                }
            }

        }).catch(async error => {
            console.error('Error showing list dialog:', error);
        });
    });


    document.getElementById('delete-selected-photos-button').addEventListener('click', async () => {
        const selectedCheckboxes = document.querySelectorAll('.student-checkbox:checked');

        if (selectedCheckboxes.length === 0) {
            return;
        }

        // Show a list to confirm deletion
        await showListDialog('Confirm Delete', 'Are you sure you want to delete these photos? This is permanent.', ['Yes', 'No'], false).then(async (selected) => {
            if (selected === 'Yes') {
                // Delete the photos
                console.log('Deleting selected photos');
                document.getElementById('delete-selected-photos-button').setAttribute('aria-busy', 'true');

                try {
                    for (const checkbox of selectedCheckboxes) {
                        const studentId = checkbox.getAttribute('data-id');
                        const studentDocRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos', studentId);
                        const studentDoc = await getDoc(studentDocRef);

                        // Delete the photo from storage
                        const photoRef = ref(storage, studentDoc.data().storageRef);
                        await deleteObject(photoRef);

                        // Delete the student document from Firestore
                        await deleteDoc(studentDocRef);

                        // Remove the row from the table
                        document.getElementById(`student-photo-${studentId}`).remove();

                        // if it's the currently shown photo, reset the UI
                        if (studentId === currentViewingPhoto) {
                            currentViewingPhoto = null;
                            document.getElementById('current-student-photo').style.display = 'none';
                            document.getElementById('current-student-desc').style.display = 'block';
                            document.getElementById('current-student-photo-download').style.display = 'none';
                            document.getElementById('current-student-photo-delete').style.display = 'none';
                        }
                    }

                    // Reset the UI
                    handleCheckboxChange();

                    // reload the table
                    await loadViewEnrolmentMode();
                } catch (error) {
                    console.error('Error deleting photos:', error.message);
                    showError('3028', 'Error Deleting Photos', 'An error occurred while deleting the photos.', error, false, true, false);
                }
            } else {
                console.log('Deletion cancelled');
                return;
            }
        }).catch(async error => {
            console.error('Error showing delete confirmation dialog:', error);
            if (error.message === 'Modal closed without selection') {
                return;
            }
            showError('3025', 'Error Deleting Photos', 'An error occurred while deleting the photos.', null, false, false);
        });

        document.getElementById('delete-selected-photos-button').setAttribute('aria-busy', 'false');
    });

    document.getElementById('select-all').addEventListener('change', function () {
        const checkboxes = document.querySelectorAll('.student-checkbox');
        checkboxes.forEach(checkbox => {
            // if there's a search query, only check the checkbox if it matches the search query
            if (document.getElementById('enrolment-search').value.length > 0) {
                const checkboxRow = checkbox.closest('tr');
                const studentName = checkboxRow.cells[1].textContent.toLowerCase();
                // Check if the student name includes the search term
                if (studentName.includes(document.getElementById('enrolment-search').value.toLowerCase())) {
                    checkbox.checked = this.checked;
                }
            } else {
                checkbox.checked = this.checked;
            }
        });
        // update the UI
        handleCheckboxChange();
    });

    // Add event listener to the search box
    document.getElementById('enrolment-search').addEventListener('input', function () {
        searchStudents(this);
    });

    // Allow shift-clicking to select multiple checkboxes
    document.querySelectorAll('.student-checkbox').forEach(checkbox => {
        checkbox.addEventListener('click', function (event) {
            if (lastChecked && event.shiftKey) {
                const checkboxes = document.querySelectorAll('.student-checkbox');
                const start = Array.from(checkboxes).indexOf(lastChecked);
                const end = Array.from(checkboxes).indexOf(this);

                checkboxes.forEach((box, index) => {
                    if (index >= Math.min(start, end) && index <= Math.max(start, end)) {
                        box.checked = lastChecked.checked; // Match the state of the last clicked checkbox
                    }
                });
            }
            lastChecked = this;
            handleCheckboxChange(); // Update the UI based on the selection
        });
    });
}

function searchStudents(searchElement) {
    const searchTerm = searchElement.value.toLowerCase();

    // Get all table rows in the tbody
    const rows = document.querySelectorAll('#enrolment-table tbody tr');

    // if there's only one row and it's the no photos found row, cannot search
    if (rows.length === 1 && rows[0].id === 'enrolment-no-photos-found') {
        return;
    }

    rows.forEach(row => {
        const studentName = row.cells[1].textContent.toLowerCase();

        // Check if the student name includes the search term
        if (studentName.includes(searchTerm)) {
            row.style.display = ''; // Show the row
        } else {
            row.style.display = 'none'; // Hide the row
        }
    });

    handleCheckboxChange();
}

async function handleStudentNameChange(photoId, newName) {
    const studentDocRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'photos', photoId);

    // Update the student document with the new name
    await updateDoc(studentDocRef, {
        studentName: newName
    }).then(() => {
        console.log('Student name updated successfully');
        // update the table row with the new name - second TD of the row
        const row = document.getElementById(`student-photo-${photoId}`);
        const studentNameElement = row.querySelector('td:nth-child(2)');
        studentNameElement.innerText = newName;

        // if the img is showing the current student/photo ID, update the name
        if (currentViewingPhoto === photoId) {
            // update the data-student-name attribute
            const img = document.getElementById('current-student-photo');
            img.setAttribute('data-student-name', newName);
        }

        // update the search results - names have changed, results may be different
        // this will also update the checkbox UI
        searchStudents(document.getElementById('enrolment-search'));
    }).catch(error => {
        console.error('Error updating student name:', error);
        showError('3028', 'Error Updating Student Name', 'An error occurred while updating the student name.', null, false, false);
    });
}

function handleCheckboxChange() {
    // Get all checkboxes (including hidden ones)
    const allCheckboxes = document.querySelectorAll('.student-checkbox');
    const allSelectedCheckboxes = Array.from(allCheckboxes).filter(checkbox => checkbox.checked);

    // Get only visible checkboxes
    const allVisibleCheckboxes = Array.from(allCheckboxes).filter(checkbox => checkbox.closest('tr').style.display !== 'none');
    const allVisibleSelectedCheckboxes = allVisibleCheckboxes.filter(checkbox => checkbox.checked);

    const deleteButton = document.getElementById('delete-selected-photos-button');
    const downloadButton = document.getElementById('download-all-photos-button');
    const selectAllCheckbox = document.getElementById('select-all');

    // If there are no visible checkboxes or not all visible checkboxes are selected, uncheck "Select All"
    if (allVisibleCheckboxes.length === 0 || allVisibleSelectedCheckboxes.length !== allVisibleCheckboxes.length) {
        selectAllCheckbox.checked = false;
    } else {
        selectAllCheckbox.checked = true;
    }

    if (allSelectedCheckboxes.length > 0) {
        // Change "Download All" to "Download Selected"
        downloadButton.textContent = 'Download Selected';

        // Show the delete selected button
        deleteButton.style.display = 'inline-block';

        // Show the selection count
        document.getElementById('enrolment-selection-count').style.display = 'block';
        document.getElementById('enrolment-selection-count').innerText = `${allSelectedCheckboxes.length} selected`;
    } else {
        // Revert "Download Selected" back to "Download All"
        downloadButton.textContent = 'Download All';

        // Hide the delete selected button
        deleteButton.style.display = 'none';

        // Hide the selection count
        document.getElementById('enrolment-selection-count').style.display = 'none';
    }
}

async function storePhotosInDrive(photoUrls, progressBar = null) {
    console.log(`storePhotosInDrive called with ${photoUrls.length} photos`);
    // if not passed an existing bar, create a new one
    if (!progressBar) {
        progressBar = createProgressBar(document.getElementById('enrolment-progress-container'));
    }

    // set to indeterminate whilst doing auth
    progressBar.switchTo('indeterminate');

    try {
        await getGoogleToken().then(async token => {
            console.log('Got token');

            // Step 1: Ask user to select a folder
            await createPicker(token).then(async folderId => {
                console.log(`Got folder ID: ${folderId}`);

                const totalPhotos = photoUrls.length;
                let currentPhoto = 0;

                progressBar.switchTo('determinate'); // switch to determinate
                progressBar.setMax(totalPhotos * 2); // set the max to double the total photos
                progressBar.setProgress(totalPhotos); // we've already done half the work - downloading the photos

                const uploadPromises = photoUrls.map(async (photoObj) => {
                    const studentName = photoObj.studentName;  // Get the studentName
                    const url = photoObj.url;  // Get the corresponding URL

                    const response = await fetch(url);
                    const blob = await response.blob();
                    console.log(`Downloaded photo for ${studentName}`);

                    const fileName = `${studentName}.jpg`;  // Use the studentName as the file name

                    await uploadFileToDrive(blob, fileName, folderId, token).then((uploadResult) => {
                        console.log(`Uploaded photo for ${studentName}`);
                        console.log(`Uploaded photo for ${studentName}`);

                        currentPhoto++;
                        progressBar.setProgress(currentPhoto * 2);

                        console.log(`----- Uploaded ${currentPhoto} of ${totalPhotos} photos to Google Drive -----`);
                        return uploadResult;
                    }).catch((error) => {
                        console.error('Error uploading photo:', error);
                        showError('3033', 'Error Uploading Photo', 'An error occurred while uploading the photo.', error, false, true, false);
                        return { error: error };
                    });

                });

                console.log('Upload promises:', uploadPromises);

                try {
                    // Wait for all uploads to complete
                    console.log('Starting uploads');
                    const results = await Promise.all(uploadPromises);
                    console.log('All photos uploaded successfully - opening Drive:', results);

                    // if there's one photo, open the photo itself
                    if (results.length === 1) {
                        window.open(`https://drive.google.com/file/d/${results[0].id}/view`, '_blank');
                    } else {
                        // Open the folder URL
                        console.log(`Opening folder ${folderId}`);
                        window.open(`https://drive.google.com/drive/folders/${folderId}`, '_blank');
                    }

                } catch (error) {
                    console.error('Error uploading one or more photos:', error);
                    showError('3029', 'Error Uploading Photos', 'An error occurred while uploading some of the photos.', error, false, true, false);
                } finally {
                    // whatever happens, we're done with the progress bar
                    document.getElementById('download-all-photos-button').setAttribute('aria-busy', 'false');
                    // done with the progress bar!
                    progressBar.remove();
                }

            }).catch(error => {
                console.error('Error picking Google Drive folder:', error);
                document.getElementById('download-all-photos-button').setAttribute('aria-busy', 'false');
                // done with the progress bar!
                progressBar.remove();

                showError('3030', 'Error Selecting Folder', 'An error occurred while selecting a Google Drive to upload the photos to. Please try again.', error, false, true, false);
            });
        }).catch(error => {
            console.error('Error getting Google token:', error);
            document.getElementById('download-all-photos-button').setAttribute('aria-busy', 'false');
            // done with the progress bar!
            progressBar.remove();

            showError('3031', 'Error Accessing Google Drive', 'An error occurred while accessing Google Drive. Please try again.', error, false, true, false);
        });

    } catch (error) {
        console.error('Error storing photos in Google Drive:', error);
        document.getElementById('download-all-photos-button').setAttribute('aria-busy', 'false');
        // done with the progress bar!
        progressBar.remove();

        showError('3032', 'Error Uploading Photos', 'An error occurred while uploading the photos.', error, false, true, false);
    }
}