// Example Usage:
// const container = document.getElementById('progress-container');
// const progressBar = createProgressBar(container, { max: 100, current: 0 });

// To switch to indeterminate:
// progressBar.switchTo('indeterminate');

// To switch to determinate:
// progressBar.switchTo('determinate');

// To set progress:
// progressBar.setProgress(50);

// To set maximum value:
// progressBar.setMax(150);

export function createProgressBar(container, options = {}) {
    let progressElement = document.createElement('progress');
    let isDeterminate = options.max !== undefined && options.current !== undefined;

    if (isDeterminate) {
        progressElement.max = options.max;
        progressElement.value = options.current;
    }

    container.appendChild(progressElement);

    function createNewProgressElement(type) {
        const newProgressElement = document.createElement('progress');
        if (type === 'determinate') {
            newProgressElement.max = progressElement.max || 100;
            newProgressElement.value = progressElement.value || 0;
        }
        container.replaceChild(newProgressElement, progressElement);
        progressElement = newProgressElement;
    }

    return {
        switchTo: function (type) {
            if (type === 'determinate') {
                isDeterminate = true;
                createNewProgressElement('determinate');
            } else if (type === 'indeterminate') {
                isDeterminate = false;
                createNewProgressElement('indeterminate');
            }
        },
        setProgress: function (value) {
            if (isDeterminate) {
                progressElement.value = value;
            }
        },
        setMax: function (maxValue) {
            if (isDeterminate) {
                progressElement.max = maxValue;
            }
        },
        remove: function () {
            container.removeChild(progressElement);
        },
    };
}